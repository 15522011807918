import PropTypes from 'prop-types'
import React from 'react'
import { Columns, Container, Hero } from 'react-bulma-components'
import { GatsbyImage } from 'gatsby-plugin-image'

function StrategyHero({ page }) {
  return (
    <Hero className="">
      <Hero.Body>
        <Container className="has-text-centered container content mb-0 pb-0">
          <Columns className="is-centered">
            <Columns.Column className="is-8">
              <h2 className="title is-size-1-desktop is-size-2-tablet is-size-2-mobile is-top-marginless mt-2 mt-3-mobile pr-6 pl-6 is-landing-text">
                Advisory Services
              </h2>
              <p className="is-size-4 pl-5 pr-5">
                Access decades of mobile experience to help you get unstuck and
                drive business growth.
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Hero.Body>
    </Hero>
  )
}
StrategyHero.propTypes = {
  page: PropTypes.object,
}

export default StrategyHero
